@if (siteConfig$ | async; as siteConfig) {
  <div class="modal-header-v2">
    <h4 class="modal-title" id="modal-basic-title">Settings</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button>
  </div>
  <div class="modal-body">
    <form>
      <!-- TODO: Admin only -->
      <div class="mb-3">
        <label for="site">Site</label>
        <select class="form-select" id="site" #site (change)="onSiteChange(site.value)">
          @for (site of environment.siteList; track site) {
            <option [selected]="site.machineName === siteConfig.ngConfig.machineName" [value]="site.machineName">
              {{ site.name }}
            </option>
          }
        </select>
      </div>
      <div class="mb-3">
        <label for="skin">Skin</label>
        <select class="form-select" id="skin" #skin (change)="onSkinChange(skin.value)">
          <option [value]="">No Skin</option>
          @for (skin of environment.skins; track skin) {
            <option [value]="skin.machineName">{{ skin.name }}</option>
          }
        </select>
      </div>
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="checkbox"
          value="true"
          id="darkMode"
          (change)="onDarkModeToggle($event)"
        />
        <label class="form-check-label" for="darkMode"> Dark Mode </label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Save</button>
  </div>
}
