'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
var prefix = 'fass';
var iconName = 'star';
var width = 576;
var height = 512;
var aliases = [11088, 61446];
var unicode = 'f005';
var svgPathData = 'M288.1 0l86.5 164 182.7 31.6L428 328.5 454.4 512 288.1 430.2 121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z';
exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};
exports.faStar = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;