import { Component, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SiteConfigService } from '../services/site-config.service';
import { first, Observable } from 'rxjs';
import { SiteConfigInterface } from '../models/config/site-config.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-settings-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent {
  siteConfig$!: Observable<SiteConfigInterface | null>;

  constructor(
    public siteConfigService: SiteConfigService,
    public activeModal: NgbActiveModal,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.siteConfig$ = this.siteConfigService.updateSiteConfig;
  }

  async onSiteChange(machineName: string) {
    await this.siteConfigService.setConfig(machineName);
  }

  async onSkinChange(skinName: string) {
    if (skinName) {
      await this.siteConfigService.setSkinConfig(skinName);
    } else {
      await this.siteConfigService.unsetSkin();
    }
  }

  onDarkModeToggle(event: any) {
    const attr = this.document.createAttribute('data-bs-theme');
    attr.value = 'dark';
    if (event.target.checked) {
      this.document.getElementById('body')?.attributes.setNamedItem(attr);
    } else {
      this.document.getElementById('body')?.attributes.removeNamedItem('data-bs-theme');
    }
  }

  protected readonly environment = environment;
}
