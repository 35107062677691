import { AuthService } from './auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, mergeMap, Observable, switchMap, take, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes(environment.anetAPIEndpoint) ||
      request.url.includes(environment.baseAPIEndpoint) ||
      request.url.includes(environment.fieldAppAPIEndpoint)
    ) {
      request = this.addHeaders(request, this.authService.getAccessToken());

      return next.handle(request).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 412) {
            return this.handle412Error(request, next);
          } else {
            return throwError(error);
          }
        }),
      );
    } else {
      return next.handle(request);
    }
  }

  private addHeaders(request: HttpRequest<any>, accessToken: string | null) {
    if (
      request.url.includes(environment.anetAPIEndpoint) ||
      request.url.includes(environment.baseAPIEndpoint) ||
      request.url.includes(environment.fieldAppAPIEndpoint)
    ) {
      const headers: any = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return request.clone({
        setHeaders: headers,
      });
    } else {
      return request;
    }
  }

  private handle412Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.GetNewAccessToken().pipe(
        switchMap((accessToken) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(accessToken);
          return next.handle(this.addHeaders(request, accessToken));
        }),
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter((accessToken) => accessToken != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addHeaders(request, accessToken));
        }),
      );
    }
  }
}
