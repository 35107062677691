import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitial',
  standalone: true,
})
export class NameInitialPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (value) {
      return value[0];
    } else {
      return '';
    }
  }
}
