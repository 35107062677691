import { Component, Inject, LOCALE_ID } from '@angular/core';
import { CurrentMeetService } from '../services/current-meet.service';
import { combineLatest, filter, map, Observable, Subscription } from 'rxjs';
import { MeetList } from '@athleticnet/athletic-live-api-models';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { DOCUMENT, AsyncPipe, NgClass } from '@angular/common';
import { AuthService } from '../anet/auth/auth.service';
import { AthleticUser } from '../anet/models/athletic-user.interface';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchBoxComponent } from '../../search/search-box/search-box.component';
import { animate, AnimationEvent, query, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IsSidebarOpenService } from '../services/is-sidebar-open.service';
import { IsDesktopService } from '../services/is-desktop.service';
import { SiteConfigService } from '../services/site-config.service';
import { SiteConfigInterface } from '../models/config/site-config.interface';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { NameInitialPipe } from '../pipes/name-initial.pipe';

@Component({
  selector: 'app-athletic-header',
  templateUrl: './athletic-header.component.html',
  styleUrls: ['./athletic-header.component.scss'],
  standalone: true,
  animations: [
    trigger('mainHeaderAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-150%)' }),
        animate('0.3s ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.3s ease-in', style({ transform: 'translateY(-150%)' })),
      ]),
    ]),
    trigger('searchBarAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-150%)' }),
        animate('0.3s ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.3s ease-in', style({ transform: 'translateY(-150%)' })),
      ]),
    ]),
  ],
  imports: [
    NameInitialPipe,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    AsyncPipe,
    NgClass,
    SearchBoxComponent,
    RouterLink,
    FontAwesomeModule,
    NgbDropdownItem,
  ],
})
export class AthleticHeaderComponent {
  currentMeet!: MeetList | null;
  athleticUser!: AthleticUser | null;
  redirectUrl!: string;
  origin!: string;
  currentUri!: string;
  searchBarActive: boolean = false;
  mainHeaderActive: boolean = true;
  barTransitionInProgress: boolean = false;

  meetUserContext$!: Observable<{ meet: MeetList | null; user: AthleticUser | null }>;
  desktop$!: Observable<boolean>;
  siteConfig$!: Observable<SiteConfigInterface | null>;
  routerSub!: Subscription;

  constructor(
    private currentMeetService: CurrentMeetService,
    private authService: AuthService,
    private router: Router,
    public isSidebarOpenService: IsSidebarOpenService,
    private isDesktopService: IsDesktopService,
    public siteConfigService: SiteConfigService,
    public modalService: NgbModal,
    @Inject(DOCUMENT) private document: any,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.routerSub = router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      // console.log(this.document.location);
      // console.log(event);
      this.redirectUrl = this.document.location.origin + event.url;
      this.origin = this.document.location.origin;
      this.currentUri = event.url;
    });
  }

  ngOnInit() {
    this.desktop$ = this.isDesktopService.isDesktop;
    this.siteConfig$ = this.siteConfigService.updateSiteConfig;
    this.meetUserContext$ = combineLatest([this.currentMeetService.updateMeet, this.authService.updateUser]).pipe(
      map(([meet, user]: [MeetList | null, AthleticUser | null]) => {
        return { meet, user };
      }),
    );
  }

  async logout() {
    await this.authService.signOut();
  }

  toggleSearch() {
    this.barTransitionInProgress = true;
    this.searchBarActive = !this.searchBarActive;
    this.mainHeaderActive = !this.mainHeaderActive;
  }

  openSettings() {
    this.modalService.open(SettingsModalComponent);
  }

  openSideMenu() {
    //console.log('openSideMenu clicked');
  }

  getRedirUrl() {
    //console.log('getRedirUrl clicked');
  }

  onMainHeaderAnimationEvent($event: AnimationEvent) {
    if (this.barTransitionInProgress) {
      this.searchBarActive = !this.searchBarActive;
      this.barTransitionInProgress = false;
    }
  }

  onSearchBarAnimationEvent($event: AnimationEvent) {
    if (this.barTransitionInProgress) {
      this.mainHeaderActive = !this.mainHeaderActive;
      this.barTransitionInProgress = false;
    }
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  protected readonly environment = environment;
}
