<ng-template #rt let-r="result" let-t="term">
  @if (r.dateString) {
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    <div>{{ r.dateString }}</div>
  }
  @if (r.type === 'Athlete') {
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    <div>{{ r.teamName }}</div>
  }
  @if (r.type === 'Team') {
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    <div i18n="@@searchBox.teamLabel">TEAM</div>
  }
  @if (r.abbrev) {
    <ngb-highlight [result]="r.shortName" [term]="t"></ngb-highlight>
    <div class="d-flex w-100 justify-content-between align-items-baseline">
      @if (r.numVideos) {
        <div style="flex-basis: 65px; text-align: right; font-size: 0.75em">
          <fa-icon [icon]="environment.icons.video" [fixedWidth]="true"></fa-icon> {{ r.numVideos }}
        </div>
      }
      @if (currentMeet) {
        <div>
          <app-event-date [meetDates]="currentMeet.meetDates" [date]="r.date"></app-event-date>
        </div>
      }
      <app-event-status
        [status]="r.eventStatus"
        [scoreboardStatus]="r.scoreboardStatus"
        [primaryEventGroup]="r.primaryEventGroup"
        [protestManagementProcess]="r.protestMgmt"
      ></app-event-status>
    </div>
  }
</ng-template>
<div class="d-flex gap-2">
  <input
    #searchBox
    appAutofocus
    type="text"
    class="form-control"
    tabindex="0"
    [placeholder]="currentSearchContext?.placeholder"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultTemplate]="rt"
    (selectItem)="selectItem($event)"
  />
  <button class="btn btn-outline-primary ml-2" (click)="closeSearch()">
    <fa-icon [icon]="environment.icons.close"></fa-icon>
  </button>
</div>
