@if (siteConfig$ | async; as siteConfig) {
  @if (meetUserContext$ | async; as meetUserContext) {
    <header id="pageHeader">
      <div id="loading-bar-container"></div>
      <div class="header-top-bar d-flex align-items-center">
        @if (searchBarActive) {
          <div id="searchBox" style="width: 100%" class="d-flex align-items-center">
            <app-search-box style="width: 100%" (toggleSearch)="toggleSearch()"></app-search-box>
          </div>
        }
        @if (mainHeaderActive) {
          <div id="mainHeader" style="width: 100%" class="d-flex align-items-center">
            @if ({ value: desktop$ | async }; as isDesktop) {
              <div class="d-flex align-items-center gap-3" style="width: 100%">
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center gap-3">
                    <fa-icon
                      class="nav-icon"
                      style="cursor: pointer"
                      (click)="isSidebarOpenService.toggleSidebar()"
                      [icon]="environment.icons.menu"
                      [fixedWidth]="true"
                    >
                    </fa-icon>
                    <a class="alive-logo" href="https://live.athletic.net">
                      <img src="assets/images/alive_gradient.svg" alt="AthleticLIVE" />
                    </a>
                    @if (meetUserContext.user?.profile?.hasAPlus) {
                      <a
                        href="https://athletic.net/userhome/userid/subscriptions/athleticplus?utm_campaign=athletic_plus&utm_medium=web&utm_source=header"
                        class="athletic-header--btn"
                        style="padding-left: 0"
                        title="Athletic+ account details"
                      >
                        <img style="width: 20px" alt="+" src="https://edge.athletic.net/athletic-logos/aPlus.svg" />
                      </a>
                    } @else {
                      <a
                        href="https://support.athletic.net/article/i6hyoj1if1-features-for-athletes-parents-fans?utm_campaign=athletic_plus&utm_medium=web&utm_source=s_header"
                        class="athletic-header--btn"
                        style="padding-left: 0; position: relative"
                        title="Your Athletic+ Subscription is not active, click for more info."
                      >
                        <fa-icon
                          [icon]="['fas', 'slash']"
                          [rotate]="90"
                          style="position: absolute; top: 1px; text-shadow: 2px 0px 0px white; left: -1px; z-index: 1"
                        ></fa-icon>
                        <img
                          style="width: 20px; opacity: 0.7"
                          alt="-"
                          src="https://edge.athletic.net/athletic-logos/aPlus.svg"
                        />
                      </a>
                    }
                  </div>
                </div>
                <div class="flex-grow-0">
                  <div class="d-flex align-items-center gap-3" style="font-size: 1.25rem">
                    <!--
                  <fa-icon (click)="openSettings()" [icon]="environment.icons.settings" [fixedWidth]="true"></fa-icon>
-->
                    <fa-icon
                      style="cursor: pointer"
                      (click)="toggleSearch()"
                      [icon]="environment.icons.search"
                      [fixedWidth]="true"
                    ></fa-icon>
                    @if (isDesktop.value && siteConfig.ngConfig.useLocale) {
                      <div class="dropdown locale-dropdown flex-grow-0" ngbDropdown>
                        <div class="locale-dropdown-toggle" ngbDropdownToggle>
                          {{ this.locale.toUpperCase() }}
                        </div>
                        <div ngbDropdownMenu role="menu">
                          <a class="d-flex" ngbDropdownItem href="{{ origin + currentUri }}">English</a>
                          <a class="d-flex" ngbDropdownItem href="{{ origin + '/fr' + currentUri }}">Français</a>
                          <a class="d-flex" ngbDropdownItem href="{{ origin + '/es' + currentUri }}">Español</a>
                        </div>
                      </div>
                    }
                    @if (meetUserContext.user?.userId) {
                      <div class="dropdown flex-grow-0" ngbDropdown>
                        <div ngbDropdownToggle>
                          @if (meetUserContext.user?.profile?.PhotoUrl) {
                            <img
                              style="border-radius: 50%; max-width: 36px; cursor: pointer"
                              src="{{ meetUserContext.user?.profile?.PhotoUrl }}=s72"
                            />
                          }
                          @if (!meetUserContext.user?.profile?.PhotoUrl) {
                            <span class="initial-profile-pic" style="cursor: pointer">
                              {{ meetUserContext.user?.profile?.FirstName | nameInitial
                              }}{{ meetUserContext.user?.profile?.LastName | nameInitial }}
                            </span>
                          }
                        </div>
                        <div ngbDropdownMenu role="menu">
                          <div class="dropdown-header">
                            {{ meetUserContext.user?.profile?.FirstName }} {{ meetUserContext.user?.profile?.LastName }}
                          </div>
                          <a
                            ngbDropdownItem
                            href="https://www.athletic.net/userhome/{{ meetUserContext.user?.userId }}/"
                            target="_blank"
                          >
                            <i class="fal fa-user-cog"></i> Account Settings
                          </a>
                          @if (meetUserContext.meet?.allowNotifications) {
                            <a ngbDropdownItem ui-sref="meet.notifications({meetId:meetUserContext.meet.id})">
                              <i class="far fa-bell fa-fw"></i> My Notifications
                            </a>
                          }
                          <a ngbDropdownItem (click)="logout()"> <i class="fa fa-sign-out fa-fw"></i> Logout </a>
                        </div>
                      </div>
                    }
                    @if (!meetUserContext.user?.userId) {
                      <a href="https://www.athletic.net/account/login/?m=rt&ReturnUrl={{ redirectUrl }}">
                        <fa-icon [icon]="environment.icons.login" [fixedWidth]="true"></fa-icon>
                        <!--
                      <div class="header-button-text">Login</div>
                      -->
                      </a>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </header>
  }
}
