@for (toast of toastService.toasts; track toast) {
  <ngb-toast [autohide]="true" [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
    <div class="d-flex">
      <div>
        {{ toast.body }}
      </div>
      <fa-icon class="me-2 m-auto" [icon]="environment.icons.close" (click)="toastService.remove(toast)"></fa-icon>
    </div>
  </ngb-toast>
}
