import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment, siteConfig } from '../../../environments/environment';
import { SearchContextInterface } from '../models/search-context.model';
import { SearchStateInterface } from '../models/search-state.model';
import { NgbHighlight, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  OperatorFunction,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { AthleticLiveElasticApiService } from '@athleticnet/athletic-live-api';
import { EventSummary, MeetList, SearchRecord } from '@athleticnet/athletic-live-api-models';
import { Router } from '@angular/router';
import { AutofocusDirective } from '../../shared/autofocus/autofocus.directive';
import { A11yModule } from '@angular/cdk/a11y';
import { CurrentMeetService } from '../../shared/services/current-meet.service';
import { AthleteModalComponent } from '../../athlete/athlete-modal/athlete-modal.component';
import { SearchSelectComponent } from '../search-select/search-select.component';
import { SiteConfigService } from '../../shared/services/site-config.service';
import { AthleticUser } from '../../shared/anet/models/athletic-user.interface';
import { SiteConfigInterface } from '../../shared/models/config/site-config.interface';
import { EventStatusComponent } from '../../event/event-status/event-status.component';
import { EventDateComponent } from '../../event/event-date/event-date.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-search-box',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbTypeahead,
    NgbHighlight,
    AutofocusDirective,
    A11yModule,
    EventStatusComponent,
    EventDateComponent,
  ],
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent {
  // @ViewChild("searchBox") searchBox!: ElementRef;
  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     if (!this.searchBox.nativeElement['focus']) {
  //       throw new Error('Element does not accept focus.');
  //     }
  //     this.searchBox.nativeElement.focus();
  //   }, 100);
  // }
  @Output() toggleSearch = new EventEmitter<boolean>();
  protected readonly environment = environment;

  searchContextMap: Map<string, SearchContextInterface> = new Map<string, SearchContextInterface>();
  currentSearchContext: SearchContextInterface | undefined;
  currentMeet: MeetList | null = null;
  meetSiteConfigContextSub!: Subscription;
  searchContextSub!: Subscription;
  enterpriseMeetFunction: any = {
    filter: {
      match: {
        e: 'athletictiming',
      },
    },
    weight: 20,
  };
  meetDateFunction: any = {
    gauss: {
      sdy: {
        origin: DateTime.now().toFormat('yyyy-MM-dd'),
        scale: '180d',
        offset: '90d',
        decay: 0.5,
      },
    },
  };
  meetSearchFunctions: any[] = [this.meetDateFunction];

  constructor(
    private currentMeetService: CurrentMeetService,
    protected modalService: NgbModal,
    private router: Router,
    protected searchService: AthleticLiveElasticApiService,
    public siteConfigService: SiteConfigService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.searchContextMap.set('aliveMeets', {
      id: 'aliveMeets',
      meetsOnly: true,
      placeholder: $localize`:@@searchBox.meetSearchPlaceholder:Search all AthleticLIVE meets`,
      searchBarTitle: $localize`:@@searchBox.meetSearchBarTitle:Search for AthleticLIVE meets`,
      searchIndex: 'live_results_meet_list',
    });
    this.searchContextMap.set('inMeet', {
      id: 'inMeet',
      meetsOnly: false,
      placeholder: $localize`:@@searchBox.inMeetSearchPlaceholder:Search for events, athletes, and teams in this meet`,
      searchBarTitle: $localize`:@@searchBox.inMeetSearchBarTitle:Search in this meet...`,
      searchIndex: 'search_record',
    });

    // Default to alive main
    this.currentSearchContext = this.searchContextMap.get('aliveMeets');

    this.meetSiteConfigContextSub = combineLatest([
      this.currentMeetService.updateMeet,
      this.siteConfigService.updateSiteConfig,
    ])
      .pipe(
        map(([currentMeet, siteConfig]: [MeetList | null, SiteConfigInterface | null]) => {
          if (currentMeet && siteConfig) {
            this.currentMeet = currentMeet;
            if (!siteConfig?.ngConfig.primarySite) {
              this.enterpriseMeetFunction.filter.match.e = siteConfig?.ngConfig.machineName;
              this.meetSearchFunctions = [this.enterpriseMeetFunction, this.meetDateFunction];
            }
            if (currentMeet) {
              this.currentSearchContext = this.searchContextMap.get('inMeet');
            } else {
              this.currentSearchContext = this.searchContextMap.get('aliveMeets');
            }
          } else {
            this.currentSearchContext = this.searchContextMap.get('aliveMeets');
          }
        }),
      )
      .subscribe();
  }

  closeSearch() {
    this.toggleSearch.emit(true);
  }

  searching = false;
  searchFailed = false;

  search: OperatorFunction<string, readonly (SearchRecord | EventSummary)[]> = (searchString$: Observable<string>) =>
    searchString$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      tap((searchString) => this.currentMeetService.setCurrentMeetSearchTerm(searchString)),
      switchMap((searchString) =>
        combineLatest([
          this.currentMeet
            ? this.searchService.search('search_record', searchString, this.currentMeet?.id || null)
            : of([]),
          this.currentMeet ? this.currentMeetService.updateSearchedEvents : of([]),
          this.searchService.search('live_results_meet_list', searchString, null, this.meetSearchFunctions),
        ])
          .pipe(
            map(([searchRecords, events, meets]: [SearchRecord[], EventSummary[], SearchRecord[]]) => {
              meets = searchRecords.length === 0 && events.length === 0 ? meets : [];
              return [
                ...events,
                ...searchRecords.filter((searchRecord) => {
                  return ['Athlete', 'Team'].indexOf(searchRecord.type) > -1;
                }),
                ...meets,
              ].slice(0, 10);
            }),
          )
          .pipe(
            tap(() => (this.searchFailed = false)),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }),
          ),
      ),
      tap(() => (this.searching = false)),
    );

  formatter = (x: SearchRecord) => x.name;

  async selectItem<T>(event: NgbTypeaheadSelectItemEvent<SearchRecord | EventSummary>) {
    if ('dateString' in event.item) {
      this.document.location.href = 'https://live.athletic.net/meets/' + event.item.id;
      return;
    } else if ('eventCategory' in event.item && event.item.eventCategory) {
      switch (event.item.eventCategory) {
        case 'Individual':
          await this.router.navigate(['meets', this.currentMeet?.id, 'events', 'individual', event.item.id]);
          break;
        case 'Relay':
          await this.router.navigate(['meets', this.currentMeet?.id, 'events', 'relay', event.item.id]);
          break;
        case 'Combined':
          await this.router.navigate(['meets', this.currentMeet?.id, 'events', 'combined', event.item.id]);
          break;
      }
    } else if ('type' in event.item && event.item.type) {
      switch (event.item.type) {
        case 'Athlete':
          await this.router.navigate(['meets', this.currentMeet?.id, 'athletes', event.item.id]);
          break;
        case 'Team':
          await this.router.navigate(['meets', this.currentMeet?.id, 'teams', event.item.id]);
          break;
      }
    }
    this.toggleSearch.emit(true);
    event.preventDefault();
  }

  ngOnDestroy() {
    if (this.meetSiteConfigContextSub) {
      this.meetSiteConfigContextSub.unsubscribe();
    }
    if (this.searchContextSub) {
      this.searchContextSub.unsubscribe();
    }
  }
}
