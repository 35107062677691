import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppToastService } from '../toast-service/app-toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-toast-display',
  standalone: true,
  imports: [CommonModule, NgbToastModule, FaIconComponent],
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
})
export class ToastDisplayComponent {
  constructor(public toastService: AppToastService) {}

  protected readonly environment = environment;
}
