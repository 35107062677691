import { DOCUMENT, Location, registerLocaleData } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowsH } from '@fortawesome/pro-regular-svg-icons/faArrowsH';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faCamcorder } from '@fortawesome/pro-regular-svg-icons/faCamcorder';
import { faCameraMovie } from '@fortawesome/pro-regular-svg-icons/faCameraMovie';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faDoorClosed } from '@fortawesome/pro-regular-svg-icons/faDoorClosed';
import { faDoorOpen } from '@fortawesome/pro-regular-svg-icons/faDoorOpen';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faFiles } from '@fortawesome/pro-regular-svg-icons/faFiles';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faGrid } from '@fortawesome/pro-regular-svg-icons/faGrid';
import { faGrid4 } from '@fortawesome/pro-regular-svg-icons/faGrid4';
import { faLineColumns } from '@fortawesome/pro-regular-svg-icons/faLineColumns';
import { faListOl } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faMoon } from '@fortawesome/pro-regular-svg-icons/faMoon';
import { faPersonRunning } from '@fortawesome/pro-regular-svg-icons/faPersonRunning';
import { faRankingStar } from '@fortawesome/pro-regular-svg-icons/faRankingStar';
import { faShareFromSquare } from '@fortawesome/pro-regular-svg-icons/faShareFromSquare';
import { faShield } from '@fortawesome/pro-regular-svg-icons/faShield';
import { faSquareStar } from '@fortawesome/pro-regular-svg-icons/faSquareStar';
import { faSunBright } from '@fortawesome/pro-regular-svg-icons/faSunBright';
import { faTrophyAlt } from '@fortawesome/pro-regular-svg-icons/faTrophyAlt';
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons/faUserGroup';
import { faUsersRectangle } from '@fortawesome/pro-regular-svg-icons/faUsersRectangle';
import { faWatch } from '@fortawesome/pro-regular-svg-icons/faWatch';
import { faArrowDown19 } from '@fortawesome/pro-solid-svg-icons/faArrowDown19';
import { faBackward } from '@fortawesome/pro-solid-svg-icons/faBackward';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCircleUser } from '@fortawesome/pro-solid-svg-icons/faCircleUser';
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose';
import { faFastBackward } from '@fortawesome/pro-solid-svg-icons/faFastBackward';
import { faFastForward } from '@fortawesome/pro-solid-svg-icons/faFastForward';
import { faForward } from '@fortawesome/pro-solid-svg-icons/faForward';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { faMobileScreenButton } from '@fortawesome/pro-solid-svg-icons/faMobileScreenButton';
import { faPeopleGroup } from '@fortawesome/pro-solid-svg-icons/faPeopleGroup';
import { faPerson } from '@fortawesome/pro-solid-svg-icons/faPerson';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRightToBracket } from '@fortawesome/pro-solid-svg-icons/faRightToBracket';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faSlash } from '@fortawesome/pro-solid-svg-icons/faSlash';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faTableList } from '@fortawesome/pro-solid-svg-icons/faTableList';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faTv } from '@fortawesome/pro-solid-svg-icons/faTv';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faVideo } from '@fortawesome/pro-solid-svg-icons/faVideo';
import { faWavePulse } from '@fortawesome/pro-solid-svg-icons/faWavePulse';
import { faStar as faStarRegular } from '@fortawesome/sharp-regular-svg-icons/faStar';
import { faStar as faStarSolid } from '@fortawesome/sharp-solid-svg-icons/faStar';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { combineLatestWith, filter, firstValueFrom, map, Subscription, SubscriptionLike } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { AuthService } from './shared/anet/auth/auth.service';
import { AthleticHeaderComponent } from './shared/athletic-header/athletic-header.component';
import { IsDarkModeService } from './shared/services/is-dark-mode.service';
import { IsDesktopService } from './shared/services/is-desktop.service';
import { IsSidebarOpenService } from './shared/services/is-sidebar-open.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { SeoService } from './shared/services/seo.service';
import { SiteFooterComponent } from './shared/site-footer/site-footer.component';
import { ToastDisplayComponent } from './shared/toast/toast-display/toast-display.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [AthleticHeaderComponent, RouterOutlet, ToastDisplayComponent, SiteFooterComponent],
})
export class AppComponent {
  title = 'alive-angular';
  private lastPoppedUrl: string | null = null;
  private yScrollStack: (number | null)[] = [];
  accessTokenSub!: Subscription;
  sidebarOpenSub!: Subscription;
  lastPoppedUrlSub!: SubscriptionLike;
  routerEventsSub!: Subscription;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private isSidebarOpenService: IsSidebarOpenService,
    private isDarkModeService: IsDarkModeService,
    private isDesktopService: IsDesktopService,
    private location: Location,
    private paginationConfig: NgbPaginationConfig,
    private localStorageService: LocalStorageService,
    private seoService: SeoService,
    library: FaIconLibrary,
    @Inject(DOCUMENT) private document: Document,
  ) {
    register();
    library.addIcons(
      faArrowDown19,
      faArrowLeft,
      faPersonRunning,
      faArrowRight,
      faArrowsH,
      faBackward,
      faBars,
      faCalendar,
      faCalendarAlt,
      faCalendarDays,
      faCheck,
      faChevronDown,
      faCameraMovie,
      faChevronRight,
      faRankingStar,
      faComment,
      faLineColumns,
      faGrid,
      faSquareStar,
      faListOl,
      faImages,
      faCamcorder,
      faChevronUp,
      faCircleUser,
      faClose,
      faDoorClosed,
      faDoorOpen,
      faDownload,
      faEllipsisH,
      faEnvelope,
      faExclamationCircle,
      faExclamationTriangle,
      faExternalLink,
      faEye,
      faFacebook,
      faFastBackward,
      faFastForward,
      faFiles,
      faFilter,
      faForward,
      faGear,
      faGrid4,
      faHome,
      faImages,
      faInfo,
      faLocationDot,
      faMobileScreenButton,
      faMoon,
      faPeopleGroup,
      faPerson,
      faQuestion,
      faQuestionCircle,
      faRightToBracket,
      faSearch,
      faShareFromSquare,
      faShield,
      faShoppingCart,
      faSlash,
      faSpinner,
      faSquareXTwitter,
      faStarRegular,
      faStarSolid,
      faSunBright,
      faTable,
      faTableList,
      faTag,
      faTriangleExclamation,
      faTrophyAlt,
      faTv,
      faUpload,
      faUserGroup,
      faUsersRectangle,
      faVideo,
      faWatch,
      faWavePulse,
      faArrowUp,
      faArrowDown,
      faMinus,
    );
  }

  async ngOnInit() {
    const localUserStorage = this.localStorageService.getLocalUserStorage();
    if (localUserStorage?.displayMode === 'dark') {
      this.isDarkModeService.setDarkMode();
    } else if (localUserStorage?.displayMode === 'light') {
      this.isDarkModeService.setLightMode();
    } else if (
      this.document.defaultView &&
      this.document.defaultView.matchMedia &&
      this.document.defaultView.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.isDarkModeService.setDarkMode();
    } else {
      this.isDarkModeService.setLightMode();
    }

    this.seoService.setSiteDefaultTags();

    registerLocaleData(localeEnGb, 'en-GB');
    this.paginationConfig.size = 'sm';
    this.paginationConfig.maxSize = 3;
    this.paginationConfig.rotate = true;

    this.accessTokenSub = this.route.queryParams.subscribe(async (params) => {
      if ('rt' in params) {
        this.authService.storeRefreshToken(params['rt']);
        await this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { rt: null },
          queryParamsHandling: 'merge',
        });
      }
      await firstValueFrom(this.authService.GetNewAccessToken());
    });

    this.sidebarOpenSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        combineLatestWith(this.isDesktopService.isDesktop),
        map(([event, isDesktop]) => {
          if (!isDesktop) {
            this.isSidebarOpenService.closeSidebar();
          }
          if (isDesktop) {
            this.isSidebarOpenService.openSidebar();
          }
        }),
      )
      .subscribe();

    this.lastPoppedUrlSub = this.location.subscribe((ev) => {
      this.lastPoppedUrl = ev.url ?? '';
      //console.log('router work', 'lastPoppedUrl', ev);
    });
    this.routerEventsSub = this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (this.isModalUrl(ev.url)) {
          this.yScrollStack.push(null);
          //console.log('router work', 'going to modal, push null into stack');
        } else if (ev.url != this.lastPoppedUrl) {
          if (this.document.defaultView) {
            this.yScrollStack.push(this.document.defaultView.scrollY);
            //console.log('router work', 'yScrollStack', this.yScrollStack);
          }
        }
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = null;
          const yScroll: number | null | undefined = this.yScrollStack.pop();
          if (yScroll) {
            if (this.document.defaultView && 'scrollTo' in this.document.defaultView) {
              this.document.defaultView.scrollTo({ left: 0, top: yScroll, behavior: 'instant' });
            }
            //console.log('router work', 'scroll to ' + yScroll, this.yScrollStack);
          } else {
            //console.log('do not scroll');
          }
        } else {
          // If navigating forward to a modal URL, then don't auto-scroll the background page to top

          if (this.isModalUrl(ev.url)) {
            //console.log('router work', 'navigating to modal, do not scroll to top');
          } else {
            //console.log('router work', 'scroll to top');
            if (this.document.defaultView && 'scrollTo' in this.document.defaultView) {
              this.document.defaultView.scrollTo({ left: 0, top: 0, behavior: 'instant' });
            }
          }
        }
      }
    });
  }

  private isModalUrl(url: string): boolean {
    const modalUrlRegexes = [/athlete\/\d+$/gm];
    let navigatingToModal = false;
    modalUrlRegexes.forEach((regex) => {
      if (url.match(regex)) {
        navigatingToModal = true;
      }
    });
    return navigatingToModal;
  }

  ngOnDestroy() {
    if (this.accessTokenSub) {
      this.accessTokenSub.unsubscribe();
    }
    if (this.sidebarOpenSub) {
      this.sidebarOpenSub.unsubscribe();
    }
    if (this.lastPoppedUrlSub) {
      this.lastPoppedUrlSub.unsubscribe();
    }
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
  }
}
