import {
  mergeApplicationConfig,
  ApplicationConfig,
  importProvidersFrom,
  APP_INITIALIZER,
  Inject,
  ErrorHandler,
} from '@angular/core';
import { provideServerRendering } from '@angular/platform-server';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { SiteConfigService } from './shared/services/site-config.service';
import { RefreshTokenInterceptor } from './shared/anet/auth/refresh-token-interceptor.service';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { AthleticLiveApiModule } from '@athleticnet/athletic-live-api';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import packageJson from '../../package.json';

export function appInitializerFn(
  httpClient: HttpClient,
  siteConfigService: SiteConfigService,
  document: Document,
): () => Observable<any> {
  return () => {
    for (let i = 0, len = environment.siteList.length; i < len; i++) {
      const site = environment.siteList[i];
      if (site.link.indexOf(document.location.origin) > -1) {
        return of(siteConfigService.setConfig(site.machineName));
      }
    }
    return of(siteConfigService.setConfig('athleticlive'));
  };
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://298f73b2bd8e727d6fdbc3bb0f549884@o71191.ingest.us.sentry.io/4507142302990336',
    integrations: [],
    ignoreErrors: [
      '0 Unknown Error',
      'AbortError: Share canceled',
      'Abort due to cancellation of share',
      'Something bad happened; please try again later',
      'Http failure response for https://api.athletic.live/api/notices/my',
      'Cannot redefine property: websredir', // https://spikefalcon.sentry.io/issues/5254578236
    ],
    release: packageJson.version,
    autoSessionTracking: false,
  });
}
const a = 6;
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      AthleticLiveApiModule.forRoot(environment.firebase, {
        searchEndpoint: environment.searchEndpoint,
        elasticEndpoint: environment.elasticEndpoint,
      }),
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      LayoutModule,
    ),
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
      //withFetch(),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [HttpClient, SiteConfigService, DOCUMENT],
    },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  ],
};
